import React, { useState, useEffect } from "react";
// Material-UI imports
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import { AuthorizedWidgetsContext } from "../../context/AuthorizedWidgets";
import { FavoriteGroupsContext } from "../../context/FavoriteGroups";
import { FavoriteWidgetsContext } from "../../context/FavoriteWidgets";
import fetchApi from "../../services/FetchApi";
import getApiEnvironment from "../../helpers/GetApiEnvironment";
import Navigation from "../../components/Navigation";
import { userIsAuthenticated } from "../../services/Authentication";

export default function Layout(props) {
    const classes = useStyles();
    const [authorizedWidgets, setAuthorizedWidgets] = useState([]);
    const [favoriteGroups, setFavoriteGroups] = useState([]);
    const [favoriteWidgets, setFavoriteWidgets] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [initialGroup, setInitialGroup] = useState(false);

    const updateAuthorized = () => {
        if (!dataFetched) {
            const authorizedWidgetsEndpoint = `${getApiEnvironment()}.gateway.ghllc.com/dashboard/users/authorizedwidgets`;
            fetchApi("GET", authorizedWidgetsEndpoint, null, false)
                .then(response => {
                    if (response.status === 200) {
                        setAuthorizedWidgets(response.data);
                        setDataFetched(true);
                    } else {
                        throw new Error(`Error Code ${response.status}`);
                    }
                })
                .catch(error => {
                    //setError(error);
                });
        } else {
            setAuthorizedWidgets(authorizedWidgets);
        }
    };

    const updateFavoriteGroups = () => {
        const favoriteGroupsEndpoint = `${getApiEnvironment()}.gateway.ghllc.com/dashboard/workspaces/favorites/groups`;
        fetchApi("GET", favoriteGroupsEndpoint, null, false)
            .then(response => {
                if (response.status === 200) {
                    setFavoriteGroups(response.data);
                } else {
                    throw new Error(`Error Code ${response.status}`);
                }
            })
            .catch(error => {
                if (error.status === 204 && !initialGroup) {
                    const defaultFavoritesGroup = {
                        groupId: null,
                        groupName: "Favorites",
                        sortOrder: 0
                    };

                    fetchApi(
                        "POST",
                        favoriteGroupsEndpoint,
                        defaultFavoritesGroup,
                        false
                    ).then(response => {
                        setInitialGroup(true);
                        //setFavoriteGroups(defaultFavoritesGroup);
                        fetchApi("GET", favoriteGroupsEndpoint, null, false)
                            .then(response => {                          
                                if (response.status === 200) {
                                    setFavoriteGroups(response.data);
                                }
                            })
                      
                    });
                }
            });
    };

    const updateFavoriteWidgets = () => {
        const favoriteWidgetsEndpoint = `${getApiEnvironment()}.gateway.ghllc.com/dashboard/workspaces/favorites`;
        fetchApi("GET", favoriteWidgetsEndpoint, null, false)
            .then(response => {
                if (response.status === 200) {
                    setFavoriteWidgets(response.data);
                } else if (response.status === 204) {
                    setFavoriteWidgets([]);
                } else {
                    throw new Error(`Error Code ${response.status}`);
                }
            })
            .catch(error => {
                if (error.status === 204) {
                    setFavoriteWidgets([]);
                }
            });
    };

    useEffect(() => {
        updateAuthorized();
    }, [dataFetched, authorizedWidgets]);

    useEffect(() => {
        updateFavoriteGroups();
        updateFavoriteWidgets();
    }, [initialGroup]);

    if (userIsAuthenticated() && window.location.pathname !== "/") {
        return (
            <AuthorizedWidgetsContext.Provider value={authorizedWidgets}>
                <FavoriteGroupsContext.Provider
                    value={[favoriteGroups, updateFavoriteGroups]}
                >
                    <FavoriteWidgetsContext.Provider
                        value={[favoriteWidgets, updateFavoriteWidgets]}
                    >
                        <div className={classes.navigationContainer}>
                            <Navigation />
                        </div>
                        {props.children}
                    </FavoriteWidgetsContext.Provider>
                </FavoriteGroupsContext.Provider>
            </AuthorizedWidgetsContext.Provider>
        );
    } else {
        return (
            <AuthorizedWidgetsContext.Provider value={authorizedWidgets}>
                <FavoriteGroupsContext.Provider
                    value={[favoriteGroups, updateFavoriteGroups]}
                >
                    <FavoriteWidgetsContext.Provider
                        value={[favoriteWidgets, updateFavoriteWidgets]}
                    >
                        {props.children}
                    </FavoriteWidgetsContext.Provider>
                </FavoriteGroupsContext.Provider>
            </AuthorizedWidgetsContext.Provider>
        );
    }
}

const useStyles = makeStyles(theme => ({
    navigationContainer: {
        flex: "0 0 100%",
        height: "80px"
    }
}));
