export default function getApiEnvironment() {
  const hostname = window.location.hostname;
  try {
    // Use the Dev API in dev & local environments
    if (
      hostname.indexOf("dev") !== -1 ||
      hostname.indexOf("localhost") !== -1
    ) {
      //return "https://localhost:3004";
      //return "http://localhost:56420";
      return "https://dev.api";
    }
    // QA API in QA environment
    else if (hostname.indexOf("qa") !== -1) {
      return "https://qa.api";
    }
    //Test environment
    else if (hostname.indexOf("test") !== -1) {
        return "https://test.api";
    }
    // Prod
    else if (hostname.indexOf("dashboard") !== -1) {
      return "https://api";
    } else {
      throw new Error(
        "API could not be found. Please connect to this site via a URL instead of an IP address."
      );
    }
  } catch (error) {
    console.log(error);
  }
}
