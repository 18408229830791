import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Custom imports
import Loading from "../../components/Loading";
import Layout from "../../containers/Layout";
import PrivateRoute from "../../components/PrivateRoute";
import ChangeTempPasswordForm from "../../components/ChangePasswordForm";
import { createResponseInterceptor } from "../../services/Authentication";

const ClientsContainer = lazy(() => import("../Clients"));
const DepositsContainer = lazy(() => import("../Deposits"));
const PaymentsContainer = lazy(() => import("../Payments"));
const WithdrawalsContainer = lazy(() => import("../Withdrawals"));
const FavoritesContainer = lazy(() => import("../Favorites"));
const FavoritesAdminContainer = lazy(() => import("../Settings"));
const Login = lazy(() => import("../../components/Login"));

export default function App() {
  createResponseInterceptor();

  return (
    <Suspense fallback={<Loading isLoading={true} />}>
      <Router>
        <Layout>
          <Switch>
            <PrivateRoute
              exact
              path="/clients"
              component={() => <ClientsContainer />}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/deposits"
              component={() => <DepositsContainer />}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/payments"
              component={() => <PaymentsContainer />}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/withdrawals"
              component={() => <WithdrawalsContainer />}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/favorites"
              component={() => <FavoritesContainer />}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <PrivateRoute
              exact
              path="/settings"
              component={() => <FavoritesAdminContainer />}
              temporary={() => <ChangeTempPasswordForm />}
            />
            <Route path="/" component={Login} />
          </Switch>
        </Layout>
      </Router>
    </Suspense>
  );
}
