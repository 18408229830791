import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const tutorialSteps = [
  {
    label:
      "Be on the lookout for the 'New' and 'Updated' badges on the widgets.",
    imgPath: "newwalkthrough0.jpg"
  },
  {
    label: "You can now add a widget to your favorites, or remove it...",
    imgPath: "newwalkthrough2.jpg"
  },
  {
    label: "...and see all your favorite widgets under the 'Favorites' tab.",
    imgPath: "newwalkthrough1.jpg"
  },
  {
    label:
      "You can click on the 'Reorder' button in the 'Favorites' tab, then drag and drop to reorder your favorite widgets.",
    imgPath: "newwalkthrough3.jpg"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 800,
    maxHeight: 800,
    flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 125,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  img: {
    height: 400,
    display: "block",
    width: 480,
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto"
  }
}));

export default function WalkthroughFavorites(props) {
  const { open, onClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      maxHeight="md"
      PaperProps={{
        style: {
          backgroundColor: "#eeeff1"
        },
        dialogPaper: {
          Height: "700px",
          Width: "650px"
        }
      }}
    >
      <div className={classes.root}>
        <DialogActions>
          <IconButton onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </DialogActions>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map(step => (
            <img
              key={step.label}
              className={classes.img}
              src={step.imgPath}
              alt={step.label}
            />
          ))}
        </SwipeableViews>

        <Paper square elevation={1} className={classes.header}>
          <Typography backgroundColor="#eeeff1" align="center">
            {" "}
            {tutorialSteps[activeStep].label}{" "}
          </Typography>
        </Paper>

        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </div>
    </Dialog>
  );
}
