import React, { useState, useEffect, useCallback } from "react";
import { Route } from "react-router-dom";
import { format, endOfISOWeek, startOfISOWeek, subDays, subWeeks, subMonths } from "date-fns";

// Custom imports
import { CompaniesContext } from "../../context/Companies";
import { SelectedCompaniesContext } from "../../context/SelectedCompanies";
import { DatesContext } from "../../context/Dates";
import { userIsAuthenticated, logOut } from "../../services/Authentication";
import fetchApi from "../../services/FetchApi";
import getApiEnvironment from "../../helpers/GetApiEnvironment";
import startOfMonth from "date-fns/esm/fp/startOfMonth/index.js";
import endOfMonth from "date-fns/endOfMonth";
import endOfDay from "date-fns/endOfDay/index";

export default function PrivateRoute(props) {
    const { component: Component, temporary: Temporary, ...rest } = props;
    const [companies, setCompanies] = useState([]);
    const [companiesEndpoint, setCompaniesEndpoint] = useState("");
    const [selectedCompanies, setSelectedCompanies] = useState({
        selectedCompanyIds: "",
        changeCompanies: () => { }
    });
    const [relationshipsFetched, setRelationshipsFetched] = useState(false);
    const [companiesFetched, setCompaniesFetched] = useState(false);
    const [dates, setDates] = useState({});

    const monthlyStartDate = format(startOfMonth(subMonths(new Date(), 13)), "MM/dd/yyyy");
    const monthlyEndDate = format(endOfMonth(subMonths(new Date(), 1)), "MM/dd/yyyy");
    const weeklyStartDate = format(startOfISOWeek(subWeeks(new Date(), 12)), "MM/dd/yyyy");
    const weeklyEndDate = format(endOfISOWeek(subWeeks(new Date(), 1)), "MM/dd/yyyy");
    const dailyStartDate = format(endOfDay(subDays(new Date(), 35)), "MM/dd/yyyy");
    const dailyEndDate = format(endOfDay(subDays(new Date(), 1)), "MM/dd/yyyy");

    const changeCompanies = useCallback(values => {
        //console.log("changeCompanies");
        const selectedCompaniesData = JSON.stringify(values);
        const selectedCompanyIds = values
            .map(option => {
                return option.companyId;
            })
            .join();
        sessionStorage.setItem("selectedCompanies", selectedCompaniesData);
        sessionStorage.setItem("selectedCompanyIds", selectedCompanyIds);
        setSelectedCompanies({
            selectedCompanies: sessionStorage.getItem("selectedCompanies"),
            selectedCompanyIds: sessionStorage.getItem("selectedCompanyIds"),
            changeCompanies
        });
    }, []);

    useEffect(() => {
        const fetchRelationships = () => {
            const relationshipsEndpoint = `${getApiEnvironment()}.gateway.ghllc.com/Dashboard/Users/Relationships`;
            fetchApi("GET", relationshipsEndpoint)
                .then(response => {
                    if (response.status === 200) {
                        sessionStorage.setItem("relationshipsAvailable", true);
                        setCompaniesEndpoint(
                            `${getApiEnvironment()}.gateway.ghllc.com/Dashboard/Users/RelationshipsCompanies`
                        );
                    } else {
                        throw new Error(`Error Code ${response.status}`);
                    }
                })
                .catch(error => {
                    if (
                        error.response.data ===
                        "Cannot retrieve relationships from a non GH internal user"
                    ) {
                        sessionStorage.setItem("relationshipsAvailable", false);
                        setCompaniesEndpoint(
                            `${getApiEnvironment()}.gateway.ghllc.com/dashboard/users/companies`
                        );
                        setCompaniesFetched(false);
                    }
                    // @todo: handle displaying errors here
                    console.log(error);
                });
            setRelationshipsFetched(true);
        };

        if (!relationshipsFetched) {
            fetchRelationships();
        }
        setCompaniesFetched(false);
    }, [
        dailyEndDate,
        dailyStartDate,
        monthlyEndDate,
        monthlyStartDate,
        relationshipsFetched,
        weeklyEndDate,
        weeklyStartDate
    ]);

    useEffect(() => {
        const fetchCompanies = () => {
            setCompanies([]);
            fetchApi("GET", companiesEndpoint)
                .then(response => {
                    if (response.status === 200) {
                        setCompanies(response.data);
                        setSelectedCompanies({
                            selectedCompanies: sessionStorage.getItem("selectedCompanies"),
                            selectedCompanyIds: sessionStorage.getItem("selectedCompanyIds"),
                            changeCompanies: changeCompanies
                        });
                    } else {
                        throw new Error(`Error Code ${response.status}`);
                    }
                })
                .catch(error => {
                    // @todo: handle displaying errors here
                    console.log(error);
                });

            setCompaniesFetched(true);
        };
        if (!companiesFetched && companiesEndpoint) {
            fetchCompanies();
        }
    }, [
        dailyEndDate,
        dailyStartDate,
        monthlyEndDate,
        monthlyStartDate,
        weeklyEndDate,
        weeklyStartDate,
        selectedCompanies,
        changeCompanies,
        companiesFetched,
        companiesEndpoint
    ]);

    useEffect(() => {
        setDates({
            monthlyStartDate,
            weeklyStartDate,
            dailyStartDate,
            monthlyEndDate,
            weeklyEndDate,
            dailyEndDate
        });
    }, [
        dailyEndDate,
        dailyStartDate,
        monthlyEndDate,
        monthlyStartDate,
        weeklyEndDate,
        weeklyStartDate
    ]);

    //console.log("selectedCompanies in route", selectedCompanies);
    //console.log("companies", companies);
    return (
        <Route
            {...rest}
            render={props =>
                userIsAuthenticated() === true ? (
                    <CompaniesContext.Provider value={companies}>
                        <SelectedCompaniesContext.Provider value={selectedCompanies}>
                            <DatesContext.Provider value={dates}>
                                <Component {...props} />
                            </DatesContext.Provider>
                        </SelectedCompaniesContext.Provider>
                    </CompaniesContext.Provider>
                ) : (
                    logOut()
                )
            }
        />
    );
}
