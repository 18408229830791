import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
// Material-UI imports
import {
  Typography,
  Button,
  Paper,
  MenuItem,
  Popper,
  ClickAwayListener,
  MenuList,
  IconButton,
  Box,
  Dialog
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import { ReactComponent as UserIcon } from "../../svg/iconUser.svg";
import { ReactComponent as BrandMark } from "../../svg/brandMark.svg";
import { logOut } from "../../services/Authentication";
import fetchApi from "../../services/FetchApi";
import getApiEnvironment from "../../helpers/GetApiEnvironment";
import Loading from "../Loading";
import Walkthrough from "../../components/Walkthough";
import WalkthroughFavorites from "../WalkthroughFavorites";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FeatureFlag from "../FeatureFlag";
import { FavoriteGroupsContext } from "../../context/FavoriteGroups";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChangePasswordForm from "../ChangePasswordForm";

export default function Navigation() {
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );
  const [lastName, setLastName] = useState(sessionStorage.getItem("lastName"));
  const [clientActive, setClientActive] = useState(false);
  const [depositActive, setDepositActive] = useState(false);
  const [favoritesActive, setFavoritesActive] = useState(false);
  const [paymentActive, setPaymentActive] = useState(false);
  const [clientsSubmenuOpen, setclientsSubmenuOpen] = useState(false);
  const [depositsSubmenuOpen, setDepositsSubmenuOpen] = useState(false);
  const [favoritesSubmenuOpen, setFavoritesSubmenuOpen] = useState(false);
  const [paymentsSubmenuOpen, setPaymentsSubmenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const anchorRefDeposits = useRef(null);
  const anchorRefFavorites = useRef(null);
  const anchorRefPayments = useRef(null);
  const [favoriteGroups] = useContext(FavoriteGroupsContext);

  // Clients Submenu
  const handleSubmenuToggle = () => {
    setclientsSubmenuOpen(prevOpen => !prevOpen);
  };
  const handleSubmenuClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setclientsSubmenuOpen(false);
  };
  const prevOpen = useRef(clientsSubmenuOpen);
  useEffect(() => {
    if (prevOpen.current === true && clientsSubmenuOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = clientsSubmenuOpen;
  }, [clientsSubmenuOpen]);

  // Deposits Submenu
  const handleDepositsSubmenuToggle = () => {
    setDepositsSubmenuOpen(prevOpenDeposits => !prevOpenDeposits);
  };
  const handleDepositsSubmenuClose = event => {
    if (
      anchorRefDeposits.current &&
      anchorRefDeposits.current.contains(event.target)
    ) {
      return;
    }
    setDepositsSubmenuOpen(false);
  };
  const prevOpenDeposits = useRef(depositsSubmenuOpen);
  useEffect(() => {
    if (prevOpenDeposits.current === true && depositsSubmenuOpen === false) {
      anchorRefDeposits.current.focus();
    }
    prevOpenDeposits.current = depositsSubmenuOpen;
  }, [depositsSubmenuOpen]);

  // Favorites Submenu
  const handleFavoritesSubmenuToggle = () => {
    setFavoritesSubmenuOpen(prevOpenFavorites => !prevOpenFavorites);
  };
  const handleFavoritesSubmenuClose = event => {
    if (
      anchorRefFavorites.current &&
      anchorRefFavorites.current.contains(event.target)
    ) {
      return;
    }
    setFavoritesSubmenuOpen(false);
  };
  const prevOpenFavorites = useRef(favoritesSubmenuOpen);
  useEffect(() => {
    if (prevOpenFavorites.current === true && favoritesSubmenuOpen === false) {
      anchorRefFavorites.current.focus();
    }
    prevOpenFavorites.current = favoritesSubmenuOpen;
  }, [favoritesSubmenuOpen]);

  //Payments Submenu
  const handlePaymentsSubmenuToggle = () => {
    setPaymentsSubmenuOpen(prevOpenPayments => !prevOpenPayments);
  };
  const handlePaymentsSubmenuClose = event => {
    if (
      anchorRefPayments.current &&
      anchorRefPayments.current.contains(event.target)
    ) {
      return;
    }
    setPaymentsSubmenuOpen(false);
  };
  const prevOpenPayments = useRef(paymentsSubmenuOpen);
  useEffect(() => {
    if (prevOpenPayments.current === true && paymentsSubmenuOpen === false) {
      anchorRefPayments.current.focus();
    }
    prevOpenPayments.current = paymentsSubmenuOpen;
  }, [paymentsSubmenuOpen]);

  let walkDoneObj;
  if (localStorage.getItem("walkDone") === "true") {
    walkDoneObj = false;
  } else {
    walkDoneObj = true;
  }
  const [open, setOpen] = useState(walkDoneObj);
  const handleClose = () => {
    localStorage.setItem("walkDone", "true");
    setOpen(false);
  };

  let walkDone2Obj;
  if (localStorage.getItem("walkDone2") === "true") {
    walkDone2Obj = false;
  } else {
    walkDone2Obj = true;
  }
  const [open2, setOpen2] = useState(walkDone2Obj);
  const handleClose2 = () => {
    localStorage.setItem("walkDone2", "true");
    setOpen2(false);
  };

    //userlink
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleUserLinkClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const openUserLink = Boolean(anchorEl);
    const id = openUserLink ? 'simple-popper' : undefined;

    const [isModalOpen, setIsModalOpen] = useState(false);

    function toggleModal() {
        setIsModalOpen(!isModalOpen);       
    }

    function closeModal() {
        toggleModal();
        handleUserLinkClick();
    }

  useEffect(() => {
    const fetchUserName = () => {
      const api = `${getApiEnvironment()}.gateway.ghllc.com/users/user`;
      fetchApi("GET", api, null, false)
        .then(response => {
          if (response.status === 200) {
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
            setClientActive(false);
            setLoading(false);
          } else {
            throw new Error(`Error Code ${response.status}`);
          }
        })
        .catch(error => {
          setError(true);
        });
    };
    fetchUserName();
  }, [firstName, lastName]);

  useEffect(() => {
    if (location.pathname.indexOf("clients") > -1) {
      setClientActive(true);
      setDepositActive(false);
      setFavoritesActive(false);
      setPaymentActive(false);
    } else if (location.pathname.indexOf("deposits") > -1) {
      setClientActive(false);
      setDepositActive(true);
      setFavoritesActive(false);
      setPaymentActive(false);
    } else if (location.pathname.indexOf("favorites") > -1) {
      setClientActive(false);
      setDepositActive(false);
      setFavoritesActive(true);
      setPaymentActive(false);
    } else if (location.pathname.indexOf("payments") > -1) {
      setClientActive(false);
      setDepositActive(false);
      setFavoritesActive(false);
      setPaymentActive(true);
    } else {
      setDepositActive(false);
      setClientActive(false);
      setFavoritesActive(false);
      setPaymentActive(false);
    }
  }, [location]);

  return (
    <Paper id="appHeader" square elevation={1} className={classes.appHeader}>
      <div className={classes.headerLeft}>
        <NavHashLink className={classes.branding} to="/clients#clientAdds">
          <div id="logoContainer" className={classes.logoContainer}>
            <BrandMark alt="Global" width="80" />
          </div>
        </NavHashLink>
        <FeatureFlag flagName="mychartsenabled">
          <NavLink
            exact
            to="/favorites"
            className={classes.menuLink}
            ref={anchorRefFavorites}
          >
            Favorites
          </NavLink>
        </FeatureFlag>
        <NavHashLink
          to="/clients#clientAdds"
          className={
            clientActive ? classes.menuLinkActiveSmall : classes.menuLinkSmall
          }
          ref={anchorRef}
        >
          Clients
        </NavHashLink>
        <div
          className={
            clientActive ? classes.menuLinkActive : classes.menuLinkIcon
          }
          onClick={handleSubmenuToggle}
        >
          {clientsSubmenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        <Popper
          open={clientsSubmenuOpen}
          anchorEl={anchorRef.current}
          className={classes.subMenu}
          placement="bottom-start"
        >
          <Paper elevation={4}>
            <ClickAwayListener onClickAway={handleSubmenuClose}>
              <MenuList autoFocusItem={clientsSubmenuOpen} id="menu-list-grow">
                <NavHashLink
                  to="/clients#clientAdds"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handleSubmenuClose}>Client Adds</MenuItem>
                </NavHashLink>
                <NavHashLink
                  to="/clients#clientRetention"
                  className={classes.menuItem}
                >
                <MenuItem onClick={handleSubmenuClose}>Client Retention</MenuItem>
                </NavHashLink>
                <NavHashLink
                  to="/clients#clientValidation"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handleSubmenuClose}>
                    Client Validation
                  </MenuItem>
                </NavHashLink>
                <NavHashLink
                  to="/clients#clientClosings"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handleSubmenuClose}>
                    Client Closings
                  </MenuItem>
                </NavHashLink>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>

        <NavHashLink
          to="/deposits#timeToFirstDeposit"
          className={
            depositActive ? classes.menuLinkActiveSmall : classes.menuLinkSmall
          }
          ref={anchorRefDeposits}
        >
          Deposits
        </NavHashLink>

        <div
          className={
            depositActive ? classes.menuLinkActive : classes.menuLinkIcon
          }
          onClick={handleDepositsSubmenuToggle}
        >
          {depositsSubmenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        <Popper
          open={depositsSubmenuOpen}
          anchorEl={anchorRefDeposits.current}
          className={classes.subMenu}
          placement="bottom-start"
        >
          <Paper elevation={4}>
            <ClickAwayListener onClickAway={handleDepositsSubmenuClose}>
              <MenuList autoFocusItem={depositsSubmenuOpen} id="menu-list-grow">
                <NavHashLink
                  to="/deposits#timeToFirstDeposit"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handleDepositsSubmenuClose}>
                    Time to First Deposit
                  </MenuItem>
                </NavHashLink>
                <NavHashLink
                  to="/deposits#deposits"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handleDepositsSubmenuClose}>
                    Client Deposits
                  </MenuItem>
                </NavHashLink>
                <NavHashLink
                  to="/deposits#draftReturns"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handleDepositsSubmenuClose}>
                    Draft Returns
                  </MenuItem>
                </NavHashLink>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>

        <NavHashLink
          to="/payments#paymentsVolume"
          className={
            paymentActive ? classes.menuLinkActiveSmall : classes.menuLinkSmall
          }
          ref={anchorRefPayments}
        >
          Payments
        </NavHashLink>

        <div
          className={
            paymentActive ? classes.menuLinkActive : classes.menuLinkIcon
          }
          onClick={handlePaymentsSubmenuToggle}
        >
          {paymentsSubmenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        <Popper
          open={paymentsSubmenuOpen}
          anchorEl={anchorRefPayments.current}
          className={classes.subMenu}
          placement="bottom-start"
        >
          <Paper elevation={4}>
            <ClickAwayListener onClickAway={handlePaymentsSubmenuClose}>
              <MenuList autoFocusItem={paymentsSubmenuOpen} id="menu-list-grow">
                <NavHashLink
                  to="/payments#paymentsVolume"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handlePaymentsSubmenuClose}>
                    Creditor Payments
                  </MenuItem>
                </NavHashLink>
                <NavHashLink
                  to="/payments#paymentsExceptions"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handlePaymentsSubmenuClose}>
                    Exceptions
                  </MenuItem>
                </NavHashLink>
                <NavHashLink
                  to="/payments#paymentsPhonePayReturns"
                  className={classes.menuItem}
                >
                  <MenuItem onClick={handlePaymentsSubmenuClose}>
                    Phone Pay Returns
                  </MenuItem>
                </NavHashLink>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>

        <NavLink exact to="/withdrawals" className={classes.menuLink}>
          Withdrawals
        </NavLink>
      </div>
          <div className={classes.headerRight}>         
              <UserIcon width="32" className={classes.userIcon} id="userIcon" onClick={handleUserLinkClick} />
              <Popper
                  id={id}
                  open={openUserLink}
                  anchorEl={anchorEl}
                  placement='bottom'
                  className={classes.subMenu}                  
              >
                  <Paper elevation={4}>
                      <Button onClick={toggleModal}>
                          Change Password
                      </Button>
                      <ChangePasswordForm open={isModalOpen}
                          CloseFunction={() => closeModal()}>
                      </ChangePasswordForm>                     
                  </Paper>
              </Popper>
              

        <Walkthrough open={open} onClose={handleClose} />
        <FeatureFlag flagName="mychartsenabled">
          <WalkthroughFavorites open={open2} onClose={handleClose2} />
        </FeatureFlag>
        {!loading ? (
          <Typography
            component="p"
            variant="body1"
            className={classes.userName}
          >
            {firstName} {lastName}
          </Typography>
        ) : (
          <Loading error={error} />
        )}

        <IconButton
          onClick={logOut}
          variant="outlined"
          color="primary"
          className={classes.settingsLink}
        >
          {" "}
          <ExitToAppIcon color="primary" />{" "}
        </IconButton>
      </div>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  appHeader: {
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: 80,
    position: "fixed",
    zIndex: 999
  },
  headerLeft: {
    display: "flex",
    alignItems: "center"
  },
  branding: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none"
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    "& svg": {
      width: theme.spacing(4),
      padding: theme.spacing(2, 3),
      "& .st0": {
        fill: "#fff !important",
        stroke: "#fff !important"
      }
    }
  },
  appName: {
    margin: theme.spacing(0, 4, 0, 2),
    height: 40,
    display: "flex",
    alignItems: "center",
    color: "#333",
    fontSize: "1rem",
    fontWeight: 400,
    "@media (min-width:720px)": {
      fontSize: "1.6rem"
    }
  },
  settingsLink: {
    cursor: "pointer",
    borderRadius: 0,
    padding: theme.spacing(1, 1),
    height: theme.spacing(7.5),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: "normal",
    outline: "none",
    borderBottom: `${theme.spacing(0.5)}px solid transparent`,
    "&.active": {
      color: theme.palette.primary.main,
      borderBottom: `${theme.spacing(0.5)}px solid ${
        theme.palette.primary.main
      }`
    }
  },

  menuLink: {
    cursor: "pointer",
    borderRadius: 0,
    padding: theme.spacing(1, 3),
    height: theme.spacing(7.5),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: "normal",
    outline: "none",
    borderBottom: `${theme.spacing(0.5)}px solid transparent`,
    "&.active": {
      color: theme.palette.primary.main,
      borderBottom: `${theme.spacing(0.5)}px solid ${
        theme.palette.primary.main
      }`
    }
  },
  menuLinkSmall: {
    cursor: "pointer",
    borderRadius: 0,
    padding: theme.spacing(1, 0.5, 1, 3),
    height: theme.spacing(7.5),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: "normal",
    outline: "none",
    borderBottom: `${theme.spacing(0.5)}px solid transparent`,
    "&.active": {
      color: theme.palette.primary.main,
      borderBottom: `${theme.spacing(0.5)}px solid ${
        theme.palette.primary.main
      }`
    }
  },
  menuLinkIcon: {
    cursor: "pointer",
    borderRadius: 0,
    padding: theme.spacing(1, 3, 1, 0),
    height: theme.spacing(7.5),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: "normal",
    outline: "none",
    borderBottom: `${theme.spacing(0.5)}px solid transparent`,
    "&.active": {
      color: theme.palette.primary.main,
      borderBottom: `${theme.spacing(0.5)}px solid ${
        theme.palette.primary.main
      }`
    }
  },
  menuLinkActive: {
    cursor: "pointer",
    borderRadius: 0,
    padding: theme.spacing(1, 3, 1, 0),
    height: theme.spacing(7.5),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: "normal",
    outline: "none",
    borderBottom: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`
  },
  menuLinkActiveSmall: {
    cursor: "pointer",
    borderRadius: 0,
    padding: theme.spacing(1, 0.5, 1, 3),
    height: theme.spacing(7.5),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: "normal",
    outline: "none",
    borderBottom: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`
  },
  menuItem: {
    textDecoration: "none",
    color: "#222",
    outline: "none"
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  userIcon: {
    marginRight: theme.spacing(1)
  },
  userName: {
    display: "none",
    "@media (min-width: 720px)": {
      display: "flex"
    }
  },
  logOut: {
    marginLeft: theme.spacing(2),
    fontSize: "1rem",
    textTransform: "none"
  },
  subMenu: {
    zIndex: 999
  }
}));
