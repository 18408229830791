import axios from "axios";
import getApiEnvironment from "../../helpers/GetApiEnvironment";
import getApiUrl from "../../helpers/GetApiUrl";
import { startSessionTimer } from "../../helpers/SessionTimer";

export function logIn(response) {
  const {
    accessToken,
    tokenExpirationDate,
    username,
    isAdUser,
    isTempPassword
  } = response.data;
  if (accessToken && tokenExpirationDate && username) {
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("expires", tokenExpirationDate);
    sessionStorage.setItem("userName", username);
    sessionStorage.setItem("adUser", isAdUser);
    sessionStorage.setItem("tempPass", isTempPassword);
  } else {
    throw new Error(`Error Code ${response.status}`);
  }
}

export function logOut() {
  if (sessionStorage.referrer === "companyWebsite") {
    let protocol =
      sessionStorage.getItem("referrerUrl") === "localhost:63159"
        ? "http://"
        : "https://";
    window.location.assign(
      `${protocol}${sessionStorage.getItem("referrerUrl")}/HomeTab.aspx`
    );
  } else {
    sessionStorage.clear();
    window.location.assign("/");
  }
}

export function userIsAuthenticated() {

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let result= {}; //The logIn function is going to look for a data object
  if(params.accessToken && params.expires && params.tokenExpirationDate && params.isTempPassword && params.isAdUser && params.username) {
    result.data = params;
    logIn(result);
  }

  if (
    sessionStorage.getItem("referrer") === "companyWebsite" &&
    sessionStorage.getItem("currentUser")
  ) {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    sessionStorage.setItem("accessToken", currentUser.accessToken);
    sessionStorage.setItem("expires", currentUser.tokenExpirationDate);
    sessionStorage.setItem("referrerUrl", currentUser.referrer);
    sessionStorage.setItem("userName", currentUser.username);
    sessionStorage.setItem("adUser", currentUser.isAdUser);
    sessionStorage.setItem("tempPass", currentUser.isTempPassword);
    startSessionTimer(logOut, 20);

    return true;
  }
  if (
    sessionStorage.getItem("accessToken") &&
    sessionStorage.getItem("expires")
  ) {
    startSessionTimer(logOut, 20);
    return true;
  }
  
  return false;
}

export function passwordIsTemporary() {
  if (sessionStorage.getItem("tempPass") === "true") {
    return true;
  }
  return false;
}

export function referrerCompanyWebsite() {
    if (sessionStorage.getItem("referrer") === "companyWebsite") {
        return true;
    }
    return false;
}



export function createResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
    // If the request succeeds, return the original response
    response => {
      return response;
    },
    // If there is an error, use the Interceptor to handle the error.
    error => {
      const status = error.response ? error.response.status : null;
      // Reject the Promise if the error is not a 401 error.
      if (status !== 401) {
        return Promise.reject(error);
        // When the response code is a 401, try to refresh the Bearer token.
      } else {
        // Eject the interceptor so it doesn't loop in case token refresh causes the 401 response
        axios.interceptors.response.eject(interceptor);
        // Post the refresh token to the API in order to receive a new Bearer token.
        const api = `${getApiEnvironment()}.${getApiUrl()}/auth/token`;
        return axios({
          method: "POST",
          url: api,
          withCredentials: true,
          crossdomain: true,
          data: {
            refreshToken: sessionStorage.getItem("refreshToken"),
            expiredAccessToken: sessionStorage.getItem("accessToken"),
            AppName: process.env.REACT_APP_CLIENT_ID,
            AppSecret: process.env.REACT_APP_CLIENT_SECRET
          }
        })
          .then(response => {
            // Save the new tokens and resolve the original Promise as true
            const { accessToken, expires, refreshToken } = response.data;
            sessionStorage.setItem("accessToken", accessToken);
            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem("expires", expires);
            return Promise.resolve(true);
          })
          .catch(error => {
            return Promise.reject(error);
          });
      }
    }
  );
}
