import React, { Fragment, useState, useEffect } from "react";
// Custom imports
import fetchApi from "../../services/FetchApi";
import getApiEnvironment from "../../helpers/GetApiEnvironment";
import getApiUrl from "../../helpers/GetApiUrl";

export default function FeatureFlag(props) {
  const [flagStatus, setFlagStatus] = useState(false);

  const fetchFeatureFlag = () => {
    const api = `${getApiEnvironment()}.${getApiUrl()}/featureflags?flagname=${
      props.flagName
    }`;
    fetchApi("GET", api)
      .then(response => {
        if (response.status === 200) {
          if (response.data[0].value === "true") {
            setFlagStatus(true);
          }
        } else {
          //fail silently
        }
      })
      .catch(error => {
        //fail silently
      });
  };

  useEffect(() => {
    fetchFeatureFlag();
  });

  return flagStatus && <Fragment>{props.children}</Fragment>;
}
