export default function getApiUrl() {
  const hostname = window.location.hostname;
  if (
    hostname.indexOf("localhost") !== -1 ||
    hostname.indexOf("dev") !== -1 ||
    hostname.indexOf("test") !== -1 ||
    hostname.indexOf("qa") !== -1
  ) {
      return "gateway.ghllc.com";
  } else {
      return "gateway.ghllc.com";
  }
}
